import axios from "../../axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import AddContentForm from "./AddArtForm";
import EditArt from "./EditArt";
import ArtView from "./ArtView";
import AdminNav from "../AdminNav/AdminNav";

function AddArt() {
  const MySwal = withReactContent(Swal);
  const [showBackdrop, setShowBackdrop] = useState(true);
  const [showSuccessPage, setShowSuccessPage] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setShowBackdrop(false);
      setShowSuccessPage(true);
    }, 1500);
  }, []);

  const { superAdmin } = useSelector((state) => state);
  const id = superAdmin?.details?._id;
  const [artResult, setArtResult] = useState([]);
  const dispatch = useDispatch();
  const API = process.env.REACT_APP_API_URL;

  useEffect(() => {
    axios
      .get(`${API}/art-lab?limit=-1&deleted=NO`)
      .then((response) => {
        if (!response.data.err) {
          const sortedArtContent = response.data.results.sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
          );
          setArtResult(sortedArtContent);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [id]);

  const [openForm, setOpenForm] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [openView, setOpenView] = useState(false);
  const [editResult, setEditResult] = useState("");
  const [viewArtId, setViewArtId] = useState(null);

  const openFormHandler = () => {
    setOpenForm(true);
  };

  const editArt = (artId) => {
    axios
      .get(`${API}/art-lab/${artId}`)
      .then((response) => {
        if (!response.data.err) {
          setEditResult(response.data.result);
          setOpenEdit(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const deleteArt = (artId) => {
    MySwal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel!",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(`${API}/art-lab/${artId}`)
          .then((response) => {
            toast.success(response.data.message);
            dispatch({ type: "refresh" });
          })
          .catch((err) => {
            toast.error(err.message || "Error deleting the art");
            console.log(err);
          });
      }
    });
  };

  const viewArt = (artId) => {
    setViewArtId(artId);
    setOpenView(true);
  };

  return (
    <>
      {showBackdrop && (
        <div className="fixed top-0 left-0 w-full h-full bg-black opacity-75 z-50 flex items-top justify-center ">
          <div className="text-white text-4xl">
            <i className="fas fa-circle-notch fa-spin "></i>
          </div>
        </div>
      )}
      {showSuccessPage && (
        <>
          {openForm ? (
            <AddContentForm />
          ) : openEdit ? (
            <EditArt data={{ editResult }} />
          ) : openView ? (
            <ArtView data={{ artId: viewArtId }} />
          ) : (
            <>
              <AdminNav />

              <div className="bg-black text-white min-h-screen flex flex-col items-center justify-center p-4 sm:p-8">
                <h3 className="block text-lg sm:text-xl font-large text-white mb-4">
                  ArtLab Management
                </h3>
                <ToastContainer
                  position="top-center"
                  autoClose={5000}
                  hideProgressBar={false}
                  newestOnTop={false}
                  closeOnClick
                  rtl={false}
                  pauseOnFocusLoss
                  draggable
                  pauseOnHover
                  theme="dark"
                />

                <button
                  onClick={openFormHandler}
                  className="bg-gray-400 text-black py-2 px-4 rounded-full mb-4"
                >
                  Add +
                </button>
                {artResult.length === 0 ? (
                  <h4 className="text-xl">No content Added</h4>
                ) : (
                  <div className="overflow-x-auto w-full">
                    <table
                      className="table-auto min-w-full border-collapse border border-green-800"
                      style={{ tableLayout: "fixed", width: "100%" }}
                    >
                      <thead>
                        <tr className="bg-gray-1000">
                          <th
                            className="border border-gray-400 px-2 sm:px-4 py-2"
                            style={{ width: "5%" }}
                          >
                            #
                          </th>
                          <th
                            className="border border-gray-400 px-2 sm:px-4 py-2"
                            style={{ width: "15%" }}
                          >
                            Service Type
                          </th>
                          <th
                            className="border border-gray-400 px-2 sm:px-4 py-2"
                            style={{ width: "20%" }}
                          >
                            Title
                          </th>
                          <th
                            className="border border-gray-400 px-2 sm:px-4 py-2"
                            style={{ width: "30%" }}
                          >
                            Sub Paragraph
                          </th>
                          <th
                            className="border border-gray-400 px-2 sm:px-4 py-2"
                            style={{ width: "10%" }}
                          >
                            Created At
                          </th>
                          <th
                            className="border border-gray-400 px-2 sm:px-4 py-2"
                            style={{ width: "10%" }}
                          >
                            Updated At
                          </th>
                          <th
                            className="border border-gray-400 px-2 sm:px-4 py-2"
                            style={{ width: "10%" }}
                          >
                            Actions
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {artResult.map((item, index) => (
                          <tr
                            key={item._id}
                            className={
                              index % 2 === 0
                                ? "bg-voilet-400"
                                : "bg-voilet-400"
                            }
                          >
                            <td className="border border-gray-400 px-2 sm:px-4 py-2">
                              {index + 1}
                            </td>
                            <td
                              className="border border-gray-400 px-2 sm:px-4 py-2"
                              style={{ wordWrap: "break-word" }}
                            >
                              {item.serviceType}
                            </td>
                            <td
                              className="border border-gray-400 px-2 sm:px-4 py-2"
                              style={{ wordWrap: "break-word" }}
                            >
                              {item.title}
                            </td>
                            <td
                              className="border border-gray-400 px-2 sm:px-4 py-2"
                              style={{ wordWrap: "break-word" }}
                            >
                              {item.subPara}
                            </td>
                            <td
                              className="border border-gray-400 px-2 sm:px-4 py-2"
                              style={{ wordWrap: "break-word" }}
                            >
                              {new Date(item.createdAt).toLocaleDateString()}
                            </td>
                            <td
                              className="border border-gray-400 px-2 sm:px-4 py-2"
                              style={{ wordWrap: "break-word" }}
                            >
                              {new Date(item.updatedAt).toLocaleDateString()}
                            </td>
                            <td
                              className="border border-gray-400 px-2 sm:px-4 py-2"
                              style={{ wordWrap: "break-word" }}
                            >
                              <div className="flex items-center">
                                <div
                                  className="cursor-pointer mr-2"
                                  style={{ wordWrap: "break-word" }}
                                  onClick={() => editArt(item._id)}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="20"
                                    height="20"
                                    fill="currentColor"
                                    className="bi bi-pencil-square"
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                    <path
                                      fillRule="evenodd"
                                      d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                                    />
                                  </svg>
                                </div>
                                <div
                                  className="cursor-pointer mr-2"
                                  style={{ wordWrap: "break-word" }}
                                  onClick={() => deleteArt(item._id)}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="20"
                                    height="20"
                                    fill="currentColor"
                                    className="bi bi-trash"
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6Z" />
                                    <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1ZM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118ZM2.5 3h11V2h-11v1Z" />
                                  </svg>
                                </div>
                                <div
                                  className="cursor-pointer"
                                  style={{ wordWrap: "break-word" }}
                                  onClick={() => viewArt(item._id)}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="20"
                                    height="20"
                                    fill="currentColor"
                                    className="bi bi-eye"
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zm-8 4a4 4 0 1 1 0-8 4 4 0 0 1 0 8z" />
                                    <path d="M8 10a2 2 0 1 0 0-4 2 2 0 0 0 0 4z" />
                                  </svg>
                                </div>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
            </>
          )}
        </>
      )}
    </>
  );
}

export default AddArt;
