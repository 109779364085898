import React, { useState } from "react";
import "./AdminLogin.css";
import axios from "../../axios";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function AdminLogin() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [err, setErr] = useState("");
  const API = process.env.REACT_APP_API_URL;

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(email, password, "email, password");

    if (email.trim() && password.trim()) {
      axios
        .patch(`${API}/admin/login`, { email, password })
        .then((response) => {
          if (!response.data.err) {
            if (response.data.results.accessToken)
              localStorage.setItem(
                "adminToken",
                response.data.results.accessToken
              );
            if (response.data.results.draftToken)
              localStorage.setItem(
                "draftToken",
                response.data.results.draftToken
              );
            toast.success("Login successful! Redirecting...");
            navigate("/dashboard");
          } else {
            setErr(response.data.message);
          }
        })
        .catch((error) => {
          console.log(error);
          setErr("An error occurred during login");
        });
    } else {
      setErr("All fields are required");
    }
  };

  return (
    <div>
      <div className="gray-background-admin">
        <div className="signup-admin">
          <div className="signup-connect-admin"></div>
          <div className="signup-classic-admin">
            <p className="errorMessage">{err}</p>
            <form className="form" onSubmit={handleSubmit}>
              <fieldset className="email">
                <input
                  type="email"
                  placeholder="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </fieldset>
              <fieldset className="password">
                <input
                  type="password"
                  placeholder="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
              </fieldset>
              <button type="submit" style={{ color: "white" }} className="btn">
                Login
              </button>
            </form>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}

export default AdminLogin;
