import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Slide from "@mui/material/Slide";
import { Link, useNavigate } from "react-router-dom";
import axios from "../../axios";
import { useDispatch } from "react-redux";

function AdminNav() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showLinks, setShowLinks] = React.useState(false);

  const handleMenuClick = () => {
    setShowLinks(!showLinks);
  };

  const handleLogout = () => {
    axios
      .patch("/admin/logout")
      .then((response) => {
        if (!response.data.err) {
          // Clear cookies
          localStorage.removeItem("adminToken");
          dispatch({ type: "refresh" });
          navigate("/login");
        }
      })
      .catch((error) => {
        console.error("There was an error logging out!", error);
      });

    function clearCookies() {
      const cookies = document.cookie.split("; ");
      for (const cookie of cookies) {
        const eqPos = cookie.indexOf("=");
        const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/`;
      }
    }
  };
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Slide
        direction="down"
        in={showLinks}
        mountOnEnter
        unmountOnExit
        timeout={{ enter: 300, exit: 100 }}
      >
        <Box
          sx={{
            position: "fixed",
            top: "70px",
            left: 0,
            right: 0,
            padding: "10px",
            backgroundColor: "black",
            zIndex: 9999,
          }}
        >
          <Button color="inherit" sx={{ display: "block", my: 1 }}>
            <Link style={{ textDecoration: "none", color: "white" }} to={"/"}>
              DASHBOARD
            </Link>
          </Button>
          <Button color="inherit" sx={{ display: "block", my: 1 }}>
            <Link
              style={{ textDecoration: "none", color: "white" }}
              to={"/art-lab"}
            >
              ARTLAB MANAGEMENT
            </Link>
          </Button>
          <Button color="inherit" sx={{ display: "block", my: 1 }}>
            <Link
              style={{ textDecoration: "none", color: "white" }}
              to={"/blog-management"}
            >
              BLOG MANAGEMENT
            </Link>
          </Button>

          <Button
            onClick={handleLogout}
            color="inherit"
            sx={{ display: "block", my: 1, color: "white" }}
          >
            LOGOUT
          </Button>
        </Box>
      </Slide>
      <AppBar
        position="static"
        sx={{ backgroundColor: "black", minHeight: "70px" }}
      >
        <Toolbar>
          <img src="/logo.png" width="50px" height="50px" />
          <Typography
            variant="h6"
            component="div"
            style={{ fontFamily: "monospace" }}
          >
            ORTMORAGENCY ADMIN PANEL
          </Typography>
          <Box sx={{ flexGrow: 1 }} />
          <IconButton
            size="large"
            edge="end"
            color="inherit"
            aria-label="menu"
            style={{ marginRight: "30px" }}
            onClick={handleMenuClick}
            sx={{
              transition: "transform 0.6s",
              transform: showLinks ? "rotate(45deg)" : "none", 
            }}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
    </Box>
  );
}

export default AdminNav;
