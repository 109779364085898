import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import App from "./App";
import { Provider } from "react-redux";
import Store from "././store/Store";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <Provider store={Store}>
    <App />
  </Provider>
  // </React.StrictMode>
);
