import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "../../axios";
import { Editor } from "@tinymce/tinymce-react"; // Import TinyMCE editor
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useNavigate } from "react-router-dom";
import AddBlog from "./AddBlog";
import AssetImageUpload from "./AssetImageUpload";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
function EditBlog({ data }) {
  const MySwal = withReactContent(Swal); // Create a Swal instance with ReactContent
  const { blogId } = data;
  const navigate = useNavigate();
  const apiKey = process.env.REACT_APP_TINY_CLOUD_API;

  const [blogData, setBlogData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [coverImage, setCoverImage] = useState(null);
  const [coverImageUrl, setCoverImageUrl] = useState(null);
  const [bannerImage, setBannerImage] = useState(null);
  const [bannerImageUrl, setBannerImageUrl] = useState(null);
  const [blogImage, setBlogImage] = useState(null);
  const [blogImageUrl, setBlogImageUrl] = useState(null);
  const [closeForm, setCloseForm] = useState(false);
  const [formData, setFormData] = useState({
    title: "",
    shortTitle: "",
    metaTitle: "",
    metaDescription: "",
    slug: "",
    image: "",
  });
  const [editorContent, setEditorContent] = useState("");

  const API = process.env.REACT_APP_API_URL;

  useEffect(() => {
    if (blogId && API) {
      axios
        .get(`${API}/blog/${blogId}`)
        .then((response) => {
          if (!response.data.err) {
            const result = response.data.result;
            setBlogData(result);

            setEditorContent(result.editorContent);

            setFormData({
              title: result.title,
              shortTitle: result.shortTitle,
              metaTitle: result.metaTitle,
              metaDescription: result.metaDescription,
              slug: result.slug,
              image: result.image.url,
            });
            setBlogImageUrl(result.image.url);
            setBannerImageUrl(result.bannerImage.url);

            setCoverImageUrl(result.coverImage.url);
          } else {
            toast.error("Error loading blog content.");
          }
        })
        .catch((err) => {
          console.error("Error fetching blog content details:", err);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [blogId, API]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value.startsWith(" ") ? value.substring(1) : value,
    });
  };

  const handleCoverImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setCoverImage(file);
      setCoverImageUrl(URL.createObjectURL(file));
    }
  };
  const handleBannerImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setBannerImage(file);
      setBannerImageUrl(URL.createObjectURL(file));
    }
  };

  const handleBlogImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setBlogImage(file);
      setBlogImageUrl(URL.createObjectURL(file));
    }
  };

  const handleRemoveBlogImage = () => {
    setBlogImage(null);
    setBlogImageUrl(null);
    setFormData({
      ...formData,
      image: "",
    });
  };
  const handleRemoveBannerImage = () => {
    axios
      .patch(`${API}/blog/remove-banner-image/${blogId}`)
      .then((response) => {
        if (!response.data.err) {
          setBannerImage(null);
          setBannerImageUrl(null);
          setFormData({
            ...formData,
            image: "",
          });
        }
      });
  };

  const handleRemoveCoverImage = () => {
    axios.patch(`${API}/blog/remove-cover-image/${blogId}`).then((response) => {
      if (!response.data.err) {
        setCoverImage(null);
        setCoverImageUrl(null);
        setFormData({
          ...formData,
          image: "",
        });
      }
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const updatedFormData = new FormData();
    // Loop through each key-value pair in formData, but skip "editorContent"
    for (const [key, value] of Object.entries(formData)) {
      updatedFormData.append(key, value);
    }

    if (blogImage) {
      updatedFormData.append("image", blogImage);
    }
    if (bannerImage) {
      updatedFormData.append("bannerImage", bannerImage);
    }
    if (coverImage) {
      updatedFormData.append("coverImage", coverImage);
    }

    updatedFormData.append("editorContent", editorContent);

    axios
      .patch(`${API}/blog/${blogId}`, updatedFormData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        if (!response.data.err) {
          toast.success("Blog updated successfully!");
          setCloseForm(true);
          navigate("/blog-management");
        } else {
          toast.error("Error updating blog.");
        }
      })
      .catch((err) => {
        console.error("Error updating blog:", err);
        toast.error("An error occurred while updating the blog.");
      });
  };
  const handleEditorChange = (content) => {
    setEditorContent(content);
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen">
        Loading...
      </div>
    );
  }
  const handleAssetImageUpload = (imageUrl) => {
    // Handle the uploaded image URL in the parent component
    console.log("Received image URL:", imageUrl);
    // You can set it in the form state or do something else with it
  };
  const showAssetImageUploadModal = () => {
    MySwal.fire({
      title: "Upload Asset Image",
      html: <AssetImageUpload onUpload={handleAssetImageUpload} />,
      showConfirmButton: false, // You can add a confirm button if needed
      showCloseButton: true,
      allowOutsideClick: false,
    });
  };
  const closeFormAndOpenPage = () => {
    setCloseForm(true);
  };

  if (closeForm) {
    return <AddBlog />;
  }

  return (
    <>
      <ToastContainer />
      {closeForm ? (
        <AddBlog />
      ) : (
        <div className="max-w-4xl mx-auto p-6 bg-white rounded-lg shadow-md">
          <h2 className="text-2xl font-bold mb-6">Edit Blog</h2>
          <div className="flex justify-end">
            <button
              onClick={closeFormAndOpenPage}
              className="bg-blue-500 text-white px-3 py-1 rounded mt-2 flex items-center"
              style={{
                backgroundImage:
                  "linear-gradient(to right, #5C258D 0%, #4389A2 51%, #5C258D 100%)",
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-arrow-left"
                viewBox="0 0 16 16"
              >
                <path
                  fillRule="evenodd"
                  d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"
                />
              </svg>
              <span className="ml-2">Back</span>
            </button>
          </div>

          {blogData ? (
            <form onSubmit={handleSubmit} className="space-y-4">
              <div>
                <label
                  htmlFor="title"
                  className="block text-sm font-medium text-gray-700"
                >
                  Title
                </label>
                <input
                  type="text"
                  id="title"
                  name="title"
                  value={formData.title}
                  onChange={handleChange}
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
              <div>
                <label
                  htmlFor="shortTitle"
                  className="block text-sm font-medium text-gray-700"
                >
                  Short Title
                </label>
                <input
                  type="text"
                  id="shortTitle"
                  name="shortTitle"
                  value={formData.shortTitle}
                  onChange={handleChange}
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
              <div>
                <label
                  htmlFor="metaTitle"
                  className="block text-sm font-medium text-gray-700"
                >
                  Meta Title
                </label>
                <input
                  type="text"
                  id="metaTitle"
                  name="metaTitle"
                  value={formData.metaTitle}
                  onChange={handleChange}
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
              <div>
                <label
                  htmlFor="metaDescription"
                  className="block text-sm font-medium text-gray-700"
                >
                  Meta Description
                </label>
                <textarea
                  id="metaDescription"
                  name="metaDescription"
                  value={formData.metaDescription}
                  onChange={handleChange}
                  rows="3"
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
              <div>
                <label
                  htmlFor="slug"
                  className="block text-sm font-medium text-gray-700"
                >
                  Slug
                </label>
                <textarea
                  id="slug"
                  name="slug"
                  value={formData.slug}
                  onChange={handleChange}
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>

              <div>
                <label
                  htmlFor="blogImage"
                  className="block text-sm font-medium text-gray-700"
                >
                  Blog Image
                </label>

                <div className="mt-2">
                  {blogImageUrl ? (
                    <>
                      <img
                        src={blogImageUrl}
                        alt="Blog Image Preview"
                        className="w-full h-auto border border-gray-300 rounded-md"
                      />
                      <button
                        type="button"
                        onClick={handleRemoveBlogImage}
                        className="mt-2 inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                      >
                        Remove Image
                      </button>
                    </>
                  ) : (
                    <input
                      type="file"
                      id="blogImage"
                      name="blogImage"
                      accept="image/*"
                      onChange={handleBlogImageChange} // Handler for blog image
                      className="mt-1 block w-full"
                    />
                  )}
                </div>
              </div>

              <div>
                <label
                  htmlFor="bannerimage"
                  className="block text-sm font-medium text-gray-700"
                >
                  Banner Image
                </label>

                <div className="mt-2">
                  {bannerImageUrl ? (
                    <>
                      <img
                        src={bannerImageUrl}
                        alt="Banner Image Preview"
                        className="w-full h-auto border border-gray-300 rounded-md"
                      />
                      <button
                        type="button"
                        onClick={handleRemoveBannerImage}
                        className="mt-2 inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                      >
                        Remove Image
                      </button>
                    </>
                  ) : (
                    <input
                      type="file"
                      id="bannerImage"
                      name="bannerImage"
                      accept="image/*"
                      onChange={handleBannerImageChange} // Handler for blog image
                      className="mt-1 block w-full"
                    />
                  )}
                </div>
              </div>

              <div>
                <label
                  htmlFor="coverImage"
                  className="block text-sm font-medium text-gray-700"
                >
                  Cover Image
                </label>

                <div className="mt-2">
                  {coverImageUrl ? (
                    <>
                      <img
                        src={coverImageUrl}
                        alt="Cover Image Preview"
                        className="w-full h-auto border border-gray-300 rounded-md"
                      />
                      <button
                        type="button"
                        onClick={handleRemoveCoverImage}
                        className="mt-2 inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                      >
                        Remove Image
                      </button>
                    </>
                  ) : (
                    <input
                      type="file"
                      id="coverImage"
                      name="coverImage"
                      accept="image/*"
                      onChange={handleCoverImageChange} // Handler for blog image
                      className="mt-1 block w-full"
                    />
                  )}
                </div>
              </div>

              <div className="mb-4">
                <button
                  type="button"
                  className="bg-indigo-500 text-white px-4 py-2 rounded"
                  onClick={showAssetImageUploadModal}
                >
                  Upload Asset Image
                </button>
              </div>

              <div className="mb-4" style={{ position: "relative" }}>
                <div className="form-group col-md-12 editor">
                  <label className="font-weight-bold">
                    {" "}
                    Editor Content <span className="required"> * </span>{" "}
                  </label>

                  <Editor
                    apiKey={apiKey}
                    value={editorContent}
                    init={{
                      plugins: [
                        // Core editing features
                        "anchor",
                        "autolink",
                        "charmap",
                        "codesample",
                        "emoticons",
                        "image",
                        "link",
                        "lists",
                        "media",
                        "searchreplace",
                        "table",
                        "visualblocks",
                        "wordcount",
                        "textcolor",
  
                        // Your account includes a free trial of TinyMCE premium features
                        // Try the most popular premium features until Nov 13, 2024:
                        "checklist",
                        "mediaembed",
                        "casechange",
                        "export",
                        "formatpainter",
                        "pageembed",
                        "a11ychecker",
                        "tinymcespellchecker",
                        "permanentpen",
                        "powerpaste",
                        "advtable",
                        "advcode",
                        "editimage",
                        "advtemplate",
                        "ai",
                        "mentions",
                        "tinycomments",
                        "tableofcontents",
                        "footnotes",
                        "mergetags",
                        "autocorrect",
                        "typography",
                        "inlinecss",
                        "markdown",
                        // Early access to document converters
                        "importword",
                        "exportword",
                        "exportpdf",
                        "code",
                        "preview",
                        "pagebreak",
                      ],
                      toolbar:
                      "undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | forecolor backcolor | link anchor image media table mergetags | addcomment showcomments | spellcheckdialog a11ycheck typography | align lineheight | checklist numlist bullist indent outdent | emoticons charmap | removeformat | code preview pagebreak",
                      tinycomments_mode: "embedded",
                      tinycomments_author: "Author name",
                      mergetags_list: [
                        { value: "First.Name", title: "First Name" },
                        { value: "Email", title: "Email" },
                      ],
                      ai_request: (request, respondWith) =>
                        respondWith.string(() =>
                          Promise.reject("See docs to implement AI Assistant")
                        ),
                      exportpdf_converter_options: {
                        format: "Letter",
                        margin_top: "1in",
                        margin_right: "1in",
                        margin_bottom: "1in",
                        margin_left: "1in",
                      },
                      exportword_converter_options: {
                        document: { size: "Letter" },
                      },
                      importword_converter_options: {
                        formatting: {
                          styles: "inline",
                          resets: "inline",
                          defaults: "inline",
                        },
                      },
                      forced_root_block: false,
                    }}
                    onEditorChange={handleEditorChange} // Handle changes
                  />
                </div>
              </div>
              <br />

              <div>
                <button
                  type="submit"
                  className="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  Update Blog
                </button>
              </div>
            </form>
          ) : (
            <p>No blog data available</p>
          )}
        </div>
      )}
    </>
  );
}

export default EditBlog;
